import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'
import { resolveLinkObjectToHref } from '/machinery/link'

import { HeadingXs, HeadingMd } from '/features/buildingBlocks/Heading'
import { ImageCover } from '/features/buildingBlocks/Image'
import { PortableTextCard } from '/features/buildingBlocks/PortableText'
import { ButtonLinkTertiary } from '/features/buildingBlocks/Button'
import { SliderButtons } from '/features/buildingBlocks/SliderButtons'

import mediaStyles from '/cssGlobal/media.css'
import styles from './SimpleContentCards.css'


export function SimpleContentCards({ title, description, items }) {
  const isMounted = useRenderOnMount()
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm) ?? false
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false

  const initialSlide = 0
  const loop = false
  const slidesPerView = isViewportLg ? 4 : isViewportMd ? 3 : isViewportSm ? 2 : 1
  const sliderDisabled = slidesPerView >= items.length
  const littleCard = sliderDisabled && items.length < 4
  const showSliderButtons = !sliderDisabled && isMounted

  const [currentSlide, setCurrentSlide] = React.useState(initialSlide)

  const { sliderRef, instanceRef } = useKeenSliderRefs({
    handleSlideChange,
    slidesNum: items.length,
    slidesPerView,
    spacing: isViewportMd ? 32 : 16,
    initialSlide,
    sliderDisabled,
    origin: 0,
    loop,
    interactionTitle: 'content-slider'
  })

  return (
    <div className={styles.component}>
      <div className={styles.introContainer}>
        <HeadingMd h={3} {...{ title }} />
        {description && <p className={styles.text}>{description}</p>}
      </div>

      <div ref={sliderRef} className={cx(styles.slider, sliderDisabled && styles.sliderDisabled, 'keen-slider')}>
        {Boolean(items.length) && items.map(item => (
          <div key={item._key} className={cx(styles.slideContainer, 'keen-slider__slide')}>
            <Card layoutClassName={styles.cardLayout} {...{ item, littleCard }} />
          </div>
        ))}
      </div>

      {showSliderButtons && (
        <SliderButtons
          previousButtonDisabled={currentSlide === 0}
          nextButtonDisabled={currentSlide === (items?.length - Math.round(slidesPerView))}
          layoutClassName={styles.sliderButtonsLayout}
          {...{ currentSlide, handleClick }}
        />
      )}
    </div>
  )

  function handleClick(idx) {
    instanceRef.current?.moveToIdx(idx)
  }

  function handleSlideChange(idx) {
    setCurrentSlide(idx)
  }
}

function Card({ item, littleCard, layoutClassName = undefined }) {
  const { title, text, image, internalOrExternalLink } = item
  const href = resolveLinkObjectToHref(internalOrExternalLink)

  return (
    <div className={cx(styles.componentCard, littleCard && styles.littleCard, layoutClassName)}>
      {image && (
        <div className={cx(styles.imageContainer, littleCard && styles.littleCard)}>
          <ImageCover aspectRatio={littleCard ? 16 / 9 : 1} {...{ image }} />
        </div>
      )}

      <HeadingXs h={4} {...{ title }} />

      <div className={styles.textContainer}>
        <PortableTextCard value={text} />
      </div>

      {internalOrExternalLink && (
        <ButtonLinkTertiary
          dataX='link-in-simple-content-card'
          label={internalOrExternalLink.label}
          targetSelf={internalOrExternalLink.targetSelf}
          layoutClassName={styles.buttonLayout}
          {...{ href }}
        />
      )}
    </div>
  )
}
